import * as React from 'react'
import classnames from 'classnames'
import _ from 'lodash'

import {resolveCss, WithTranslateProps, WithRouterProps} from '$common/utils'
import SvgIcon from '$common/components/icons/SvgIcon'
import diamondIcon from '$assets/icons/diamond_navBar.svg'
import magnifyingGlassIcon from '$assets/icons/magnifying_glass.svg'
import cardIcon from '$assets/icons/icon-card.svg'
import starIcon from '$assets/icons/starNavBar.svg'
import menuIcon from '$assets/icons/menu-burger.svg'
import {
  getStudentCardColor,
  STUDENT_CARD_COLOR
} from '$src/offline-studentcard/components/StudentCardColor'

import css from './NavBar.css'
import {getAppboy} from '$common/pgWebSeparatorUtils'
import {
  logEvent2,
  ANALYTICS_EVENT,
  ANALYTICS_PROVIDER
} from '$src/common/utils/logUtils'

type Props = WithTranslateProps &
  WithRouterProps & {
    css?: object
    selected?: string
    isGray?: boolean
    profile: object
    offer: object
    offerType: string
  }

type State = {}

const disableNavBarPathsAbsolute = [
  '/',
  '/login',
  '/signup',
  '/suggest-login',
  '/logout'
]

const disableNavBarPathsByPrefix = ['/profile/verify', '/no-picture/']

export function navBarDisabledForRoute(route: string) {
  if (disableNavBarPathsAbsolute.includes(route)) {
    return true
  }
  return disableNavBarPathsByPrefix.some((routePrefix) => {
    return route.indexOf(routePrefix) >= 0
  })
}

class NavBar extends React.Component<Props, State> {
  onNavigationClick = (pathname: string, analyticsEvent: string) => {
    return () => {
      const isBrickAndMortarStore =
        this.props.offer &&
        (this.props.offer.offerType === 2 ||
          this.props.offer.voucherType === '2')
      if (pathname === '/studentcard' && isBrickAndMortarStore) {
        const offerType = this.props.offer.isVoucher ? 'voucher' : 'offer'
        getAppboy().logCustomEvent(
          `Opened student ID from ${offerType}`,
          _.pick(this.props.offer, ['key', 'advertiser'])
        )
      }

      logEvent2(analyticsEvent, [
        ANALYTICS_PROVIDER.AMPLITUDE,
        ANALYTICS_PROVIDER.BRAZE,
        ANALYTICS_PROVIDER.BRANCH
      ])

      window.scrollTo(0, 0)
      this.props.history.push(pathname)
    }
  }

  static isSafeAreaDevice() {
    if (!window.device) {
      return false
    }

    const {model} = window.device

    return (
      model === 'iPhone10,3' || // X
      model === 'iPhone10,6' || // X
      model === 'iPhone11,2' || // XS
      model === 'iPhone11,6' || // XS Max
      model === 'iPhone11,8' || // XR
      model === 'iPhone12,1' || // 11
      model === 'iPhone12,3' || // 11 Pro
      model === 'iPhone12,5' || // 11 Pro Max,
      model === 'iPhone13,1' || // iPhone 12 Mini
      model === 'iPhone13,2' || // iPhone 12
      model === 'iPhone13,3' || // iPhone 12 Pro
      model === 'iPhone13,4' || // iPhone 12 Pro Max
      model === 'iPhone14,2' || // iPhone 13 Pro
      model === 'iPhone14,3' || // iPhone 13 Pro Max
      model === 'iPhone14,4' || // iPhone 13 Mini
      model === 'iPhone14,5' || // iPhone 13
      model === 'iPhone14,6' || // iPhone SE 3rd Gen
      model === 'iPhone14,7' || // iPhone 14
      model === 'iPhone14,8' || // iPhone 14 Plus
      model === 'iPhone15,2' || // iPhone 14 Pro
      model === 'iPhone15,3' // iPhone 14 Pro Max
    )
  }

  render() {
    const classes = resolveCss(css, this.props.css)

    if (
      !window.cordova ||
      navBarDisabledForRoute(this.props.location.pathname)
    ) {
      return null
    }

    const navItems = [
      {
        defaultClasses: [classes('iconDefaultColor').className],
        pathname: '/home',
        icon: diamondIcon,
        label: 'Discover',
        analyticsEvent: ANALYTICS_EVENT.NAVBAR_BUTTON_PRESSED_HOMEPAGE
      },
      {
        defaultClasses: [classes('iconDefaultColor').className],
        pathname: '/studentcard',
        icon: cardIcon,
        label: 'Student Card',
        analyticsEvent: ANALYTICS_EVENT.NAVBAR_BUTTON_PRESSED_STUDENTCARD
      },
      {
        defaultClasses: [classes('iconDefaultColor').className],
        pathname: '/offers',
        icon: magnifyingGlassIcon,
        label: 'Discounts',
        analyticsEvent: ANALYTICS_EVENT.NAVBAR_BUTTON_PRESSED_OFFERPAGE
      },
      {
        defaultClasses: [classes('iconDefaultColor').className],
        pathname: '/favourites',
        icon: starIcon,
        label: 'Favourites',
        analyticsEvent: ANALYTICS_EVENT.NAVBAR_BUTTON_PRESSED_FAVOURITES
      },
      {
        defaultClasses: [classes('menuContainer').className],
        pathname: '/menu',
        icon: menuIcon,
        label: 'More',
        analyticsEvent: ANALYTICS_EVENT.NAVBAR_BUTTON_PRESSED_MENU
      }
    ]

    const showNavBarBorder =
      getStudentCardColor(this.props.profile) === STUDENT_CARD_COLOR.WHITE &&
      this.props.location.pathname === '/studentcard'
    return (
      <nav
        aria-label="Bottom Menu"
        {...classes(
          'container',
          this.props.isGray && 'gray',
          showNavBarBorder && 'navBarBorder',
          NavBar.isSafeAreaDevice() && 'safeAreaBottom'
        )}
      >
        {navItems.map((item) => {
          const selectedClass = classes('selected').className
          return (
            <div
              key={item.label}
              role="menuitem"
              aria-label={item.label}
              className={classnames(...item.defaultClasses, {
                [selectedClass]:
                  this.props.location.pathname.indexOf(item.pathname) >= 0
              })}
              onClick={this.onNavigationClick(
                item.pathname,
                item.analyticsEvent
              )}
            >
              <div {...classes('iconContainer')}>
                <SvgIcon {...classes('icon')} src={item.icon} />
              </div>
            </div>
          )
        })}
      </nav>
    )
  }
}

export default NavBar
