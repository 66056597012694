import * as React from 'react'
import {NavLink as Link} from 'react-router-dom'
import SvgIcon from '$common/components/icons/SvgIcon'
import LanguageSelector from '$src/i18n/components/LanguageSelector'
import {resetState} from '$common/sagas'
import align from '$common/grid/align.css'
import css from './index.css'
import grid from '$common/grid/grid.css'
import logoSrc from '$assets/icons/frankLogoSolidWhite.svg'
import signIn from '$assets/icons/signIn.svg'
import filter from '$assets/icons/icon-filter.svg'
import profileSrc from '$assets/icons/profile.svg'
import HeaderTopFill from './HeaderTopFill'
import BurgerSvg from '$assets/icons/menu-burger-header.svg'
import BackButton from '$common/components/buttons/BackButton'
import SearchIcon from '$common/components/icons/SearchIcon'
import SearchInput from '$src/offers/components/ListPage/SearchInput'
import NotificationIconUnFilled from '$assets/icons/notifications-bell-icon.svg'
import SearchTermList from '$src/offers/components/ListPage/SearchTermList'

import {
  resolveCss,
  urlWithRedirect,
  WithRunSagaProps,
  WithRouterProps,
  WithTranslateProps,
  WithResponsiveInfoProps
} from '$common/utils'

type Props = WithResponsiveInfoProps &
  WithTranslateProps &
  WithRouterProps &
  WithRunSagaProps & {
    css?: any
    isLoggedIn: boolean
    onFilterClick?: () => any
    isFilter?: boolean
    isFilterOpen?: boolean
    isSecondGradeStudent: boolean
    isUserVerified: boolean
    isMenuPage: boolean
  }

type State = {
  menuIsOpen: boolean
}

class Header extends React.Component<Props, State> {
  login = () => {
    this.props.runSaga(resetState)
    this.props.history.push(urlWithRedirect('/', this.props.location))
  }

  filterIcon = () => (
    <div
      className={window.cordova ? css.filterIconCordova : ''}
      onClick={this.props.onFilterClick}
      role="menuitem"
      aria-label="Filter"
    >
      <SvgIcon
        css={{
          container: [
            css.icon,
            css.iconFilter,
            this.props.isFilterOpen && css.selectedIconFilter
          ]
        }}
        sizing="none"
        src={filter}
      />
    </div>
  )

  onSearchClick = () => {
    this.props.initiateSearch(this.props.search)
    this.props.setSearchTermToStorage(this.props.search)
  }

  searchIcon = () => (
    <div onClick={this.onSearchClick}>
      <SearchIcon css={{container: css.searchIcon}} />
    </div>
  )

  renderTopRightMenuIcons = (isFilter, classes) => {
    if (window.cordova) {
      return this.props.withSearch ? (
        <div
          role="button"
          aria-label="Search button"
          {...classes('searchIconMobile', 'searchIconCordova')}
        >
          {this.searchIcon()}
        </div>
      ) : null
    }

    return !this.props.isLoggedIn ? (
      this.props.isDesktop ? (
        <div {...classes('rightContent')}>
          <SvgIcon
            onClick={this.login}
            css={{container: css.loginIcon}}
            sizing="none"
            src={signIn}
          />
          <LanguageSelector compact css={{container: css.languageSelector}} />
        </div>
      ) : !isFilter ? (
        <LanguageSelector compact css={{container: css.languageSelector}} />
      ) : (
        <div {...classes('searchAndFilterContainer')}>
          {this.props.withSearch && (
            <div {...classes('searchIconMobile')}>{this.searchIcon()}</div>
          )}
          <div {...classes('filterIconMobile')}>{this.filterIcon()}</div>
        </div>
      )
    ) : (
      <div {...classes('searchAndFilterContainer')}>
        {!isFilter ? (
          <Link to="/profile/settings">
            <SvgIcon
              css={{container: css.iconProfile}}
              sizing="none"
              src={profileSrc}
            />
          </Link>
        ) : (
          <>
            {this.props.withSearch && (
              <div {...classes('searchIconMobile')}>{this.searchIcon()}</div>
            )}
            <div {...classes('filterIconMobile')}>{this.filterIcon()}</div>
          </>
        )}
      </div>
    )
  }

  headerLeftPart = (isFilter, classes) =>
    window.cordova ? (
      isFilter ? (
        <div {...classes('middle')} onClick={this.props.onFilterClick}>
          {this.filterIcon()}
        </div>
      ) : (
        <div {...classes('middle')} />
      )
    ) : (
      <div {...classes('middle')}>
        {this.props.isMenuPage ? (
          <BackButton onClick="goBack" style="bold" />
        ) : (
          <Link to="/menu">
            <SvgIcon
              src={BurgerSvg}
              sizing="none"
              css={{container: css.iconMenu}}
            />
          </Link>
        )}
      </div>
    )

  render() {
    const classes = resolveCss(css, this.props.css, grid, align)
    const isFilter = this.props.isFilter || false
    const {
      isDesktop,
      isUserVerified,
      isSecondGradeStudent,
      withNotifications,
      unreadNotificationsCount,
      toggleNotificationFeed,
      loggedIn
    } = this.props
    return (
      <header {...classes('wrapper')}>
        <HeaderTopFill />
        <div {...classes('container')}>
          {!isDesktop && this.headerLeftPart(isFilter, classes)}
          <div {...classes('left')}>
            <div {...classes('linkWrapper', !this.props.isDesktop && 'center')}>
              {isDesktop || !this.props.withSearch ? (
                <Link to="/home">
                  <SvgIcon
                    css={{container: css.iconLogo}}
                    sizing="none"
                    src={logoSrc}
                  />
                </Link>
              ) : (
                <div
                  {...classes(
                    'searchContainer',
                    window.cordova
                      ? 'searchBarWidthCordova'
                      : 'searchBarWidthMobileBrowser'
                  )}
                >
                  <SearchInput
                    initialValue={this.props.search}
                    updateSearchToParentState={
                      this.props.updateSearchToParentState
                    }
                    initiateSearch={this.props.initiateSearch}
                    setShowFilterDropDown={this.props.setShowFilterDropDown}
                    setShowSearchTermList={this.props.setShowSearchTermList}
                    setSearchTermToStorage={this.props.setSearchTermToStorage}
                    termClick={this.props.termClick}
                  />
                  {this.props.showSearchTermList && (
                    <SearchTermList
                      setShowSearchTermList={this.props.setShowSearchTermList}
                      searchTerms={this.props.searchTerms}
                      initiateSearch={this.props.initiateSearch}
                      setSearchTerms={this.props.setSearchTerms}
                      onSearchTermClick={this.props.onSearchTermClick}
                      t={this.props.t}
                    />
                  )}
                </div>
              )}
            </div>

            {isDesktop && (
              <div {...classes('linkWrapper')}>
                <Link to="/offers" activeClassName={css.active}>
                  <div {...classes('linkContent')}>
                    {this.props.t('common.discounts')}
                  </div>
                </Link>
              </div>
            )}

            {isDesktop && isUserVerified && !isSecondGradeStudent && (
              <div {...classes('linkWrapper')}>
                <Link to="/ordercard" activeClassName={css.active}>
                  <div {...classes('linkContent')}>
                    {this.props.t('common.ordercard')}
                  </div>
                </Link>
              </div>
            )}
          </div>
          {isDesktop && <div {...classes('middle')} />}
          <div {...classes('right')}>
            {this.renderTopRightMenuIcons(isFilter, classes)}
            {loggedIn && withNotifications && window.cordova && (
              <a
                role="menuitem"
                aria-label="Notification feed"
                {...classes('notification')}
                onClick={
                  this.props.notificationFeedIsVisible
                    ? () => toggleNotificationFeed('close')
                    : () => toggleNotificationFeed('open')
                }
              >
                {unreadNotificationsCount !== 0 && (
                  <span {...classes('badge')}>{unreadNotificationsCount}</span>
                )}
                <SvgIcon
                  css={{container: css.notificationIcon}}
                  sizing="none"
                  src={NotificationIconUnFilled}
                />
              </a>
            )}
          </div>
        </div>
      </header>
    )
  }
}

export default Header
